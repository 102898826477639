@import "../fonts.css";
.TermsAndConditions {
    padding: 30px;
    font-family: 'Play', sans-serif;
    background: #060c02;
    color: #eaefe0;
}
    .TermsAndConditionsInner {
        width: 1200px;
        margin: 0px auto;
    }
    .TermsAndConditions h1 {
        margin: 0 0 20px 0;
        font-size: 25px;
        font-weight: bold;
    }
    .TermsAndConditions h3 {
        margin: 40px 0 0 0;
        font-weight: bold;
        font-size: 18px;
    }
    .TermsAndConditions h4 {
        margin: 0 0 20px 0;
        font-weight: bold;
    }
    .TermsAndConditions a {
        color: #fff;
    }
    .TermsAndConditions ul {
        margin: 0 0 0 15px;
        list-style-type: circle;
    }
        .TermsAndConditions ul li {
            line-height: 25px;
        }
    .TermsAndConditions p {
        margin: 10px 0 0;
        line-height: 25px;
    }