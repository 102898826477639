
.subscribe_form {
  display: flex;
  margin: 16px 0 0 0;
  align-items: center;
  justify-content: center;
}
.subscribe_form input {
  display: flex;
  width: 268px;
  height: 16px;
  padding: 14px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  border: 2px solid #FFFFFF;
  background: rgba(255, 255, 255, 0.4);
}
.subscribe_form input::placeholder {
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-weight: bold;
}
.subscribe_form button {
  display: flex;
  width: 160px;
  height: 48px;
  margin: 0 0 0 12px;
  color: #000000;
  background: #FFFFFF;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  border: none;
  outline: none;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.subscribe_form button:hover {
  cursor: pointer;
  color: #FFFFFF;
  background: #000000;
}
.subscribe_form button:disabled:hover {
  background-color:#FFFFFF;
  opacity: 0.9;
  color: rgba(16, 16, 16, 0.3);
}

.form-row {
  position: relative;
}
.form-row.lost-focus.invalid .form-field.email,
.form-row.lost-focus.invalid .form-field.email:focus-visible {
  border: 2px solid #FF9999;
  outline: none;
}
.form-row .validation-overlay {
  display: none;
  position:absolute;
  bottom: 0;
  right: 0;
  /*min-width: 50%;*/
  background-color: #FFFFFF55;
  font-size: 12px;
  text-align: right;
  padding: 4px 15px 4px;
  box-sizing: border-box;
  color: #F44;
}
.form-row.lost-focus.invalid .validation-overlay {
  display: block;
}

.success-submit-message {
  padding: 10px 20px;
  flex: 1 1 0px;
  font-size: 16px;
  color: #FFF;
}

@media (max-width: 490px) {
  .subscribe_form input {
    width: 170px;
  }
  .subscribe_form button {
    width: 120px;
  }
}
@media (max-width: 320px) {
  .subscribe_form input {
    width: 145px;
  }
  .subscribe_form button {
    width: 100px;
    font-size: 14px;
  }
}

@media (max-width: 280px) {
  .subscribe_form input {
    width: 115px;
  }
}
