@import "fonts.css";
.App {
  font-family: 'Play', sans-serif;
}
.content {
  min-height: calc(100vh - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
  .top {
    width: 100%;
    height: 720px;
    background: #000000 url('static/images/top_bg.jpg') center top no-repeat;
    text-align: center;
  }
    .logo {
      margin: 80px auto 0;
      width: 700px;
      height: 394px;
      background: url('static/images/logo2.png') left top no-repeat;
      background-size: cover;
    }
    .subtitle {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 32px;
    }
    .social_wrp {
      width: 313px;
      margin: 56px auto 0;
    }
      .social {
        display: grid;
        grid-template-columns: repeat(4, 48px);
        column-gap: 40px;
      }
        .social a {
          display: block;
          width: 48px;
          height: 48px;
          text-indent: -9999px;
        }
        .social a:hover {
          opacity: 0.8;
        }
        .social a.discord { background: url('static/images/discord_icon.png') left top no-repeat }
        .social a.youtube { background: url('static/images/youtube_icon.png') left top no-repeat }
        .social a.steam { background: url('static/images/steam_icon.png') left top no-repeat }
        .social a.instagram { background: url('static/images/instagram_icon.png') left top no-repeat }
.section2 {
  width: 100%;
  padding: 133px 0;
  color: #FFFFFF;
  background: #000000 url('static/images/2section_bg.png') center top no-repeat;
}
  .section2 h3 {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }
  .section2 p {
    width: 865px;
    margin: 27px auto 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
  }
.section3 {
  width: 100%;
  padding: 100px 0;
  text-align: center;
  background: #000000 url('static/images/3section_bg.png') center top no-repeat;
}


  .intouch {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
  }
footer {
  display: flex;
  height: 120px;
  padding: 0 45px;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  background: #000000;
}
  .copy {
    display: flex;
    align-items: center;
  }
  .footer_social {
    display: flex;
    align-items: center;
  }

@media (max-width: 1100px) {
  .section2 p {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .logo {
    width: 204px;
    height: 164px;
    background: url('static/images/logo_simple.png') left top no-repeat;
  }
  .subtitle {
    font-size: 25px;
  }
  .section2 {
    padding: 60px 0;
  }
    .section2 h3 {
      font-size: 25px;
    }
    .section2 p {
      font-size: 18px;
      line-height: 24px;
    }
  .section3 {
    padding: 60px 0;
  }
  footer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
    .copy {
      margin: 25px 0 0 0;
    }
}
@media (max-width: 320px) {
  .logo {
    width: 150px;
    height: 121px;
    background: url('static/images/logo_simple.png') left top no-repeat;
    background-size: cover;
  }
  .subtitle {
    font-size: 20px;
  }
  .section2 {
    padding: 30px 0;
  }
  .section2 h3 {
    font-size: 20px;
  }
  .section2 p {
    font-size: 16px;
    line-height: 24px;
  }
  .intouch {
    font-size: 20px;
  }
}
